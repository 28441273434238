<template>
  <div class="artifacts">
    <section>
      <div class="content">
        <EditableField
          class="is-size-3 has-text-weight-bold"
          required
          placeholder="something here"
          :value="ACTIVE_WORKSPACE_PROJECT.title"
          @confirm="confirm('title', $event)"
          >
          <div>
            {{ACTIVE_WORKSPACE_PROJECT.title}}
            <span class="fa fa-xs fa-pencil-alt is-grey-light"></span>
          </div>
        </EditableField>
        <EditableField
          type="textarea"
          required
          placeholder="something here"
          :value="ACTIVE_WORKSPACE_PROJECT.description"
          @confirm="confirm('description', $event)"
          >
          <div>
            {{ACTIVE_WORKSPACE_PROJECT.description}}
            <span class="fa fa-xs fa-pencil-alt is-grey-light"></span>
          </div>
        </EditableField>
      </div>
    </section>
    <section>
      <b-table
        v-if="!modifiedUserStoriesFilteredByCurrentProjectId.length"
        :hoverable="true"
        :sort-multiple="true"
        :data="modifiedUserStoriesFilteredByCurrentProjectId"
        :columns="columns">
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon
                  icon="grin"
                  size="is-large">
                </b-icon>
              </p>
              <p>There are no stories in this project.</p>
              <button class="button is-primary is-medium"
                @click="isComponentModalActive = true">
                Create a Story
              </button>
            </div>
          </section>
        </template>
      </b-table>
      <b-modal :active.sync="isComponentModalActive"
          has-modal-card
          full-screen
          trap-focus
          aria-role="dialog"
          aria-modal>
        <modal-form :projectTitle="ACTIVE_WORKSPACE_PROJECT.title" />
      </b-modal>
      <b-table
        v-if="modifiedUserStoriesFilteredByCurrentProjectId.length"
        :data="modifiedUserStoriesFilteredByCurrentProjectId"
        :hoverable="true"
        :sort-multiple="true"
        :striped="true"
        :narrowed="true"
        :mobile-cards="false"
      >
        <b-table-column field="title" label="Story Name" :searchable="true" v-slot="props">
            <router-link :to="`/${ACTIVE_WORKSPACE.title}/stories/${props.row._id}`">
            {{ props.row.title }}
            </router-link>
        </b-table-column>

        <b-table-column field="creatorFullName" label="Reporter" :searchable="true" v-slot="props">
            {{ props.row.creatorFullName }}
        </b-table-column>

        <b-table-column field="storyType" label="Type" :searchable="true" v-slot="props">
            {{ props.row.storyType }}
        </b-table-column>

        <b-table-column field="priority" label="Priority" :searchable="true" v-slot="props">
            {{ props.row.priority }}
        </b-table-column>

        <b-table-column field="statusTitle" label="Status" :searchable="true" v-slot="props">
            {{ props.row.statusTitle }}
        </b-table-column>

        <b-table-column field="createdAt" label="Created On" :searchable="true" v-slot="props">
            <span class="tag is-success">
                {{ new Date(props.row.createdAt).toLocaleDateString() }}
            </span>
        </b-table-column>

        <b-table-column field="updatedAt" label="Modified On" :searchable="true" v-slot="props">
            <span class="tag is-success">
                {{ new Date(props.row.createdAt).toLocaleDateString() }}
            </span>
        </b-table-column>

        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon
                  icon="frown-open"
                  size="is-large">
                </b-icon>
              </p>
              <p>No Data to display.</p>
            </div>
          </section>
        </template>
      </b-table>
    </section>
    <p></p>
  </div>
</template>

<script>
import { forEach } from 'lodash';
import { mapGetters } from 'vuex';
import store from '@/store';
import { CONSTANTS as PROJECT_CONSTANTS, } from '@/store/modules/project/project.constants';
import { CONSTANTS as USER_CONSTANTS } from '@/store/modules/user/user.constants';
import { CONSTANTS as STORY_CONSTANTS } from '@/store/modules/story/story.constants';
import { CONSTANTS as WORKSPACE_CONSTANTS, } from '@/store/modules/workspace/workspace.constants';
import { CONSTANTS as BOARD_CONSTANTS, } from '@/store/modules/board/board.constants';
import EditableField from '@/components/pureComponents/EditableField.component.vue';
import ProjectModel from '@/models/project.model';
import ProjectService from '@/services/project.service';
import ModalForm from './Stories/CreateStoryForm.vue';

const clone = require('rfdc')({ proto: false });

export default {
  name: 'ProjectView',
  components: { EditableField, ModalForm, },
  data() {
    return {
      isComponentModalActive: false,
      titleEditMode: false,
      title: '',
    };
  },
  computed: {
    ...mapGetters([
      USER_CONSTANTS.GETTERS.USERS,
      WORKSPACE_CONSTANTS.GETTERS.ACTIVE_WORKSPACE,
      BOARD_CONSTANTS.GETTERS.BOARD_STATUSES,
      STORY_CONSTANTS.GETTERS.USER_STORIES,
    ]),
    projectId() {
      return this.$route.params._id;
    },
    // https://jonathanland.design/articles/functional-computed-properties-in-vue-components/
    ACTIVE_WORKSPACE_PROJECT: ({ projectId }) => store
      .getters[PROJECT_CONSTANTS.GETTERS.ACTIVE_WORKSPACE_PROJECT](projectId),
    userStoriesFilteredByCurrentProjectId() {
      return this.USER_STORIES.filter(story => story.project === this.projectId);
    },
    searchable() {
      return !!this.userStoriesFilteredByCurrentProjectId.length;
    },
    creatorIdToCreatorFullNameMapping() {
      const creatorIdToCreatorFullNameObject = {};
      const { USERS } = this;

      forEach(USERS, (user) => {
        creatorIdToCreatorFullNameObject[user._id] = user.fullName;
      });

      return creatorIdToCreatorFullNameObject;
    },
    modifiedUserStoriesFilteredByCurrentProjectId() {
      const copy = clone(this.userStoriesFilteredByCurrentProjectId);
      return copy.map((story) => {
        // fixes 'no-param-reassign' eslint error
        // https://stackoverflow.com/questions/35637770/how-to-avoid-no-param-reassign-when-setting-a-property-on-a-dom-object
        const storyCopy = Object.assign({}, story);

        storyCopy.creatorFullName = this.creatorIdToCreatorFullNameMapping[story.creator];

        this.BOARD_STATUSES.forEach((status) => {
          if (status._id === storyCopy.status) storyCopy.statusTitle = status.title;
        });

        return storyCopy;
      });
    },
    columns() {
      const { searchable } = this;

      return [
        {
          field: 'title',
          label: 'Story Name',
          searchable,
        },
        {
          field: 'creatorFullName',
          label: 'Reporter',
          searchable,
        },
        {
          field: 'storyType',
          label: 'Type',
          searchable,
        },
        {
          field: 'priority',
          label: 'Type',
          searchable,
        },
        {
          field: 'statusTitle',
          label: 'Status',
          searchable,
        },
        {
          field: 'createdAt',
          label: 'Created On',
          searchable,
        },
        {
          field: 'updatedAt',
          label: 'Modified On',
          searchable,
        },
      ];
    },
  },
  methods: {
    confirm(key, value) {
      const { projectId: _id } = this;

      ProjectService.updateProject(new ProjectModel({ _id, [key]: value }))
        .catch(error => console.error(error));
    }
  },
  created() {
    this.$store
      .dispatch(USER_CONSTANTS.ACTIONS.USERS_REQUEST)
      .catch(error => console.error(error));

    this.$store
      .dispatch(WORKSPACE_CONSTANTS.ACTIONS.WORKSPACE_STORIES_REQUEST)
      .catch(error => console.error(error));
  },
};
</script>

/* https://stackoverflow.com/questions/41321592/scoped-css-not-being-applied-within-the-component */
<style lang="scss" scoped>
.content {
  h1:hover {
    cursor: pointer;
  }
}
</style>
